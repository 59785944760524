<template>
  <div id="sequence-editor-app" @click="ToggleActionSidebar" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
    <div  id="sequence-panel"  class="vx-row w-full">    <!-- {{ nodePidHobjectNameDictionnary }} -->
      <!-- -->
      <!-- {{ hobjects }} -->
      <!-- {{ this.selected_action.id }} -->
      <vs-sidebar class="third-vs-sidebar" parent="sequence-panel" v-model="is_navigation_sidebar_open" :hidden-background="true" position-left default-index="1" color="primary" spacer>
        <vx-card class="mt-4" title="Sequences by start type"  @click.stop>
          <div v-for="trigger_type in triggerTypeOptions" :key="trigger_type.value">
            <vs-row vs-type="flex" vs-justify="space-between">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6" class="sm:p-0 p-4 text-left">
                <h6 :title="trigger_type.text">{{ trigger_type.text }}</h6>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="6" class="sm:p-0 p-4 text-center">
                <vs-button
                  class="p-1 mb-1 inline-flex text-gray"
                  v-on:click="AddSequence(trigger_type)"
                  size="small"
                  color="primary"
                  type="flat"
                  >New Sequence
                </vs-button>
              </vs-col>
            </vs-row>
            <div class="ml-4" v-for="seq in sequences" :key="seq.id">
              <vs-row
                v-if="seq.trigger.trigger_type.value == trigger_type.value"
                vs-type="flex"
                vs-justify="space-between"
              >
                <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="10" class="sm:p-0 p-4 text-left">
                  <li
                    class="hover"
                    v-on:click="selectSequence(seq)"
                    v-if="seq.trigger.trigger_type.value == trigger_type.value"
                  >
                    <template v-if="seq.name">{{ seq.name }}</template>
                    <template v-else> {{ showTriggerSubtitle(seq) }}</template>
                  </li></vs-col
                >
                <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="2" class="sm:p-0 p-4 text-center">
                  <feather-icon
                    icon="TrashIcon"
                    v-on:click="RemoveSequence(seq)"
                    svgClasses="h-4 w-4 cursor-pointer text-gray"
                    class="hover:text-danger mr-1"
                  />
                </vs-col>
              </vs-row>
            </div>
            <vs-divider class="mt-1 mb-2"></vs-divider>
          </div>
        </vx-card>
        <vx-card class="mt-3">
          <center>
            <span>scan this code to test your sequences</span>
          </center>
          <div class="mt-2 flex">
            <div class="m-auto">
              <div class="qr-code align-middle text-center">
                <vue-qr
                  height="150px"
                  :margin="0"
                  :logoSrc="hoverlayIconSrc"
                  :dotScale="0.7"
                  colorDark="#444444"
                  :size="1000"
                  :callback="getData64fromQrCode"
                  :text="qrCodeUrl"
                  :logoMargin="0"
                  :correctLevel="3"
                ></vue-qr>
              </div>
              <div class="align-middle text-center">
                <!-- <vs-button size="small" color="dark" type="flat">
                  <feather-icon size="small" icon="DownloadIcon" v-on:click="onQrCodeDownload" />
                </vs-button>  -->
                <vs-button
                  size="small"
                  v-clipboard:copy="qrCodeUrl"
                  color="dark"
                  v-clipboard:success="onQrCodeCopy"
                  type="flat"
                >
                  <feather-icon size="small" icon="LinkIcon" />
                </vs-button>
              </div>
            </div>
          </div>
        </vx-card>
      </vs-sidebar>
      <div id="workspace" class="mx-auto w-1/3">
        <div class="mt-4" v-if="selected_sequence">
          <div class="mb-4 flex">
            <div class="flex header-sequence w-full">
              <div>
                <input 
                  v-if="is_sequence_name_editing" 
                  v-model="temp_sequence_name" 
                  @keydown.enter.prevent="saveSequenceNameEdit"
                  @blur="saveSequenceNameEdit"
                />
                <h4 
                  v-else 
                  class="text mt-2 mr-4"
                  @click="enableSequenceNameEditing"
                >
                  {{selected_sequence.name}}
                </h4>
              </div>
              <vs-checkbox v-model="selected_sequence.repeat">Repeats</vs-checkbox>
              <!-- <vs-button class="ml-8" type="border" @click="startSequence(1)" color="primary">Start</vs-button> -->
            </div>
          </div>
          <div class="editable-text"></div>
          <div class="timeline">
            <div class="outer">
              <div :id="selected_sequence.trigger.id" class="card mb-5" @click.stop>
                <div class="info">
                  <!-- <h3 class="title"></h3> -->
                  <div class="timeline-info">
                    <p class="font-semibold">Start when</p>

                    <!-- <span class="activity-desc">{{ selected_sequence.trigger.trigger_type.text }} </span> -->
                  </div>
                  <!-- <small class="text-grey activity-e-time">2Emin</small> -->

                  <!-- <span>Trigger Type:</span> -->
                  <v-select
                    :clearable="false"
                    v-model="selected_sequence.trigger.trigger_type"
                    label="text"
                    class="mb-2 w-full"
                    :options="triggerTypeOptions"
                  />
                  <!-- {{ selected_sequence.target }} -->
                  <!-- <br /> -->
                  <!-- {{ node_options }} -->
                  <!-- {{ this.selected_sequence }} -->

                  <div v-if="selected_sequence.trigger.trigger_type.value == 'trigger_match_to_place'">
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <vs-checkbox class="mb-3 mt-3" v-model="selected_sequence.show_preview">Show Preview</vs-checkbox>
                      </div>
                      <div v-show="selected_sequence.show_preview" class="vx-col w-1/2">
                        <span>Preview Text:</span>
                        <vs-input class="w-full mb-3" name="name" v-model="selected_sequence.preview_instructions_text" />
                      </div>
                      <div v-show="selected_sequence.show_preview" class="vx-col w-1/2">
                        <span>Preview Button Text:</span>
                        <vs-input class="w-full mb-3" name="name" v-model="selected_sequence.preview_button_text" />
                      </div>
                      <div class="vx-col w-full">
                        <span>Instructions Text:</span>
                        <vs-input class="w-full mb-3" name="name" v-model="selected_sequence.instructions_text" />
                      </div>
                      <div class="vx-col w-full">
                        <span>Instructions Button Text:</span>
                        <vs-input class="w-full mb-3" name="name" v-model="selected_sequence.instructions_button_text" />
                      </div>
                      <!-- <div class="vx-col w-1/2">
                        <span>Smooth Time (seconds):</span>
                        <vs-input-number
                          min="0"
                          max="100"
                          :step="0.25"
                          class="w-full mb-5"
                          v-model="selected_sequence.smooth_time"
                          icon-inc="expand_less"
                          icon-dec="expand_more"
                        />
                      </div>
                      <div class="vx-col w-1/2">
                        <span>Max speed:</span>
                        <vs-input-number
                          min="0"
                          max="100"
                          :step="0.25"
                          class="w-full mb-5"
                          v-model="selected_sequence.max_speed"
                          icon-inc="expand_less"
                          icon-dec="expand_more"
                        />
                      </div> -->
                    </div>
                  </div>

                  <div v-if="selected_sequence.trigger.trigger_type.value == 'trigger_amqp_message_received'">
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <span>Host:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.host" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Port:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.port" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Username:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.username" />
                      </div>
                    </div>

                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <span>Password:</span>
                        <vs-input class="w-full mb-2" v-model="selected_sequence.password" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>VirtualHost:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.virtual_host" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Exchange:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.exchange" />
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <span>Routing Key:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.routing_key" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>SelectedToken:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.selected_token" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Message:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.message" />
                      </div>
                    </div>
                  </div>
                  <div v-if="selected_sequence.trigger.trigger_type.value == 'trigger_mqtt_message_received'">
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <span>Host:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.host" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Username:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.username" />
                      </div>
                      <div class="vx-col w-1/3">
                        <span>Password:</span>
                        <vs-input class="w-full mb-2" v-model="selected_sequence.password" />
                      </div>
                    </div>

                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <span>Read Topic:</span>
                        <vs-input class="w-full mb-2" name="name" v-model="selected_sequence.read_topic" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="
                      selected_sequence.trigger.trigger_type.value == 'trigger_screen_tap' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_image_anchor_found' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_image_anchor_lost'
                    "
                  >
                    <div v-show="selected_sequence.trigger.trigger_type.value != 'trigger_screen_tap'">
                      <span>Reference Image:</span>
                      <v-select
                        :clearable="false"
                        v-model="selected_sequence.anchor"
                        label="name"
                        class="mb-3 w-full"
                        :options="referenceImageOptions"
                      />
                    </div>
                    <div
                      v-if="
                        selected_sequence.trigger.trigger_type.value == 'trigger_image_anchor_found' ||
                          selected_sequence.trigger.trigger_type.value == 'trigger_screen_tap'
                      "
                    >
                      <vs-checkbox class="mb-3" v-model="selected_sequence.show_instructions"
                        >Show instructions</vs-checkbox
                      >
                      <div v-if="selected_sequence.show_instructions">
                        <span>Instructions text:</span>
                        <vs-input class="w-full mb-3" name="name" v-model="selected_sequence.instructions_text" />
                        <!-- <span>Instructions text background</span>
                  <vs-input
                    class="w-full mb-3"
                    placeholder="#000000"
                    name="name"
                    v-model="selected_sequence.instructions_text_background"
                  /> -->
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      selected_sequence.trigger.trigger_type.value == 'trigger_tap' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_enter_portal' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_exit_portal' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_proximity_enter' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_proximity_exit'
                    "
                  >
                    <span>Object(s):</span>
                    <v-select
                      :clearable="false"
                      v-model="selected_sequence.targets"
                      multiple
                      label="text"
                      class="mb-2 w-full"
                      :options="nodePidHobjectNameOptions"
                    />
                  </div>
                  <div
                    v-if="
                      selected_sequence.trigger.trigger_type.value == 'trigger_match_to_place' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_tap_to_place'
                    "
                  >
                    <span v-show="selected_sequence.trigger.trigger_type.value == 'trigger_match_to_place'"
                      >Object to use as stencil</span
                    >
                    <span v-show="selected_sequence.trigger.trigger_type.value == 'trigger_tap_to_place'"
                      >Object to use as reference (optional)
                    </span>
                    <v-select
                      v-model="selected_sequence.target"
                      label="text"
                      class="mb-2 w-full"
                      :options="nodePidHobjectNameOptions"
                    />
                  </div>
                  <div
                    v-if="
                      selected_sequence.trigger.trigger_type.value == 'trigger_proximity_enter' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_proximity_exit'
                    "
                  >
                    <span>Distance (meters):</span>
                    <vs-input
                      min="0"
                      max="10000"
                      :step="1"
                      class="w-full mb-5"
                      v-model="selected_sequence.distance"
                      icon-inc="expand_less"
                      icon-dec="expand_more"
                    />
                  </div>
                  <center>
                    <!-- <vs-icon @click.stop="removeTrigger(selected_sequence)" icon="delete" size="medium" color="primary"></vs-icon> -->
                  </center>
                  <div
                    v-if="
                      selected_sequence.trigger.trigger_type.value == 'trigger_tap' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_image_anchor_found' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_screen_tap' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_enter_portal' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_exit_portal' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_proximity_enter' ||
                        selected_sequence.trigger.trigger_type.value == 'trigger_proximity_exit'
                    "
                  ></div>
                </div>
              </div>
              <draggable :list="selected_actions" :actions="selected_actions"  @click.stop>
                <div
                  v-for="action in selected_actions"
                  :key="action.id"
                  :name="action.id"
                  :id="action.id"
                  v-on:click="SelectAction(action.id, action)"
                  @click.stop
                  class = "w-full vx-row flex-col card"
                  :class="[selected_action && selected_action.id == action.id ? 'selected-card' : '', getActionClass(action)]"
                >
                <div class="vx-row">
                  <div class="vx-col w-2/12 mt-2 mb-2 items-center">
                    <div class="action-type-icon round-icon">
                      <vs-icon
                                color=white
                                disabled
                                type="flat"
                                class="border-2"
                                size="20px"
                                :icon="iconActionDic[action.type]"
                              >
                      </vs-icon>
                    </div>
                  </div>
                  <div class="vx-col w-8/12 flex mt-2 mb-2 items-center">
                    <p>{{ showActionSubtitle(action) }}</p>
                  </div>
                  <div class="vx-col w-2/12 flex items-center">
                    <vs-row vs-type="flex" vs-justify="flex-end">
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                        <vs-icon
                          v-on:click="removeAction(action)"
                          icon="delete"
                          size="small"
                          color="primary"
                        ></vs-icon>
                      </vs-col>
                    </vs-row>
                  </div>
                </div>
                   <!-- <div class="vx-col action-type-icon round-icon">
                    <vs-icon
                        color=white
                        disabled
                        type="flat"
                        class="border-2"
                        size="20px"
                        :icon="iconActionDic[action.type]"
                      >
                    </vs-icon>
                  </div> 
                  <div class="vx-col items-center" >
                      <span class="mb-1 truncate">{{ showActionSubtitle(action) }}</span>
                  </div>                   -->
                </div>
              </draggable>
            </div>
          </div>

          <div class="vx-row mt-5 ml-2" @click.stop>
            <!-- <div class="vx-full "><span>Action: </span></div> -->
            <span class="mt-3 text-gray">Add a new action:</span>
            <div class="flex">
              <vx-tooltip text="Show">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_show')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_show']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Hide">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_hide')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_hide']"
                ></vs-button>
              </vx-tooltip>
              <div class="break"></div>
              <!-- break -->
              <vx-tooltip text="Play">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_play')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_play']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Pause">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_pause')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_pause']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Toggle Play Pause">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_toggle_play_pause')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_toggle_play_pause']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Stop">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_stop')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_stop']"
                ></vs-button>
              </vx-tooltip>
              <div class="break"></div>
              <!-- break -->
              <vx-tooltip text="Spin">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_spin')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_spin']"
                ></vs-button>
              </vx-tooltip>
              <!-- <vx-tooltip text="Transform By">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_transform_by')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_transform_by']"
                ></vs-button>
              </vx-tooltip> -->
              <vx-tooltip text="Animate">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_animate')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_animate']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Play Particles (Unity Only)">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_play_particles')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_play_particles']"
                ></vs-button>
              </vx-tooltip>
              <div class="break"></div>
              <!-- break -->
              <vx-tooltip text="Change Space">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_change_space')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_change_space']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Open Link">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_open_url')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_open_url']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Show Instruction">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_show_instructions')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_show_instructions']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Reset Experience Origin">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_set_experience_origin')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_set_experience_origin']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Start Tagging Along Screen">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_start_following')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_start_following']"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Stop Tagging Along Screen">
                <vs-button
                  color="primary"
                  type="gradient"
                  @click="AddAction('action_stop_following')"
                  class=" mt-4 mr-3 icon-size"
                  :icon="iconActionDic['action_stop_following']"
                ></vs-button>
              </vx-tooltip>
            </div>
            <!-- <div class="vx-col w-7/12">
              <span>Action: </span>
              <v-select
                :clearable="false"
                v-model="selected_action_type"
                label="text"
                class="w-full"
                :options="actionTypes"
              />
            </div>
            <div class="vx-col w-5/12">
              <vs-button
                v-if="this.selected_sequence"
                @click="AddAction()"
                class="w-full mr-4 mt-6"
                type="border"
                color="primary"
                >Add Action</vs-button
              >
            </div> -->
          </div>
        </div>
      </div>
      <vs-sidebar class="third-vs-sidebar"  @click.stop  parent="sequence-panel" v-model="is_action_inspector_open" :hidden-background="true" position-right default-index="1" color="primary" spacer>
        <div class="mt-4"  @click.stop v-if="selected_actions && selected_actions.length > 0 && !selected_action" title="Action">
          <h4 class="m-4">Select an action to change its properties</h4>
        </div>
        <div v-else-if="selected_action" @click.stop>
          <h3 class="m-4">{{ showActionSubtitle(selected_action) }}</h3>
          <div>
            <vs-collapse accordion type="border">
              <vs-collapse-item>
                <div slot="header" class="font-semibold">TIMING</div>
                <div class="vx-row">
                  <div v-if="selected_action != selected_actions[0]" class="vx-col w-1/2">
                    <span>Start:</span>
                    <v-select
                      :clearable="false"
                      v-model="selected_action.start"
                      label="text"
                      class="w-full mb-2"
                      :options="actionsStartOptions"
                    />
                  </div>
                  <div class="vx-col w-1/2">
                    <span>Delay: (seconds)</span>
                    <vs-input
                      min="0"
                      max="512"
                      :step="0.5"
                      class="w-full mb-5"
                      v-model="selected_action.delay"
                      icon-inc="expand_less"
                      icon-dec="expand_more"
                    />
                  </div>
                  <div v-if="selected_action != selected_actions[0]" class="vx-col w-full mb-3">
                    <div v-if="selected_action.start.value == 'after_previous'">
                      Starts when previous action finishes.
                    </div>
                    <div v-else>Starts simultaneously with the previous selected_action.</div>
                  </div>
                  <div class="vx-col w-1/2">
                    <span>Repeat</span>
                    <vs-input
                      min="0"
                      max="10000"
                      :step="1"
                      class="w-full mb-5"
                      v-model="selected_action.repeat"
                      icon-inc="expand_less"
                      icon-dec="expand_more"
                    />
                  </div>
                  <div
                    v-if="selected_action.hasOwnProperty('wait_until_finished') || selected_action.type == 'action_play'"
                    class="vx-col w-full"
                  >
                    <vs-checkbox class="mb-5" v-model="selected_action.wait_until_finished"
                      ><template v-if="selected_action.type == 'action_open_url'">Wait until closed</template
                      ><template v-else>Wait until finished</template>
                    </vs-checkbox>
                  </div>
                </div>
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">
                  <div class="flex items-center">
                    <span class="mr-5 font-semibold">SELECTED OBJECT(S)</span>
                    <!-- <vs-avatar class="border-2 border-white border-solid -m-2" :src="hobjects[1].cropped_image" size="32px" />
                    <vs-avatar class="border-2 border-white border-solid -m-2" :src="hobjects[1].cropped_image" size="32px" />
                    <vs-avatar class="border-2 border-white border-solid -m-2" :src="hobjects[1].cropped_image" size="32px" /> -->
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div
                      v-if="
                        selected_action.type == 'action_change_space' ||
                          selected_action.type == 'action_set_experience_origin' ||
                          selected_action.type == 'action_open_url' ||
                          selected_action.type == 'action_show_instructions' ||
                          selected_action.type == 'action_send_amqp_message' ||
                          selected_action.type == 'action_send_mqtt_message'
                      "
                    >
                      <div>Not Applicable</div>
                    </div>
                    <div v-else>
                      <vs-checkbox class="mb-5" v-model="selected_action.target_all_objects"
                        >If checked, all objects are selected
                      </vs-checkbox>
                      <div v-show="!selected_action.target_all_objects">
                        <span>Selected Object(s):</span>
                        <v-select
                          :clearable="false"
                          v-model="selected_action.targets"
                          multiple
                          label="text"
                          class="w-full mb-2"
                          :options="nodePidHobjectNameOptions"
                        />
                      </div>
                    </div>
                  </div></div
              ></vs-collapse-item>
              <template
                v-if="
                  selected_action.type != 'action_show' &&
                    selected_action.type != 'action_hide' &&
                    selected_action.type != 'action_open_url' &&
                    selected_action.type != 'action_play_particles' &&
                    selected_action.type != 'action_send_amqp_message' &&
                    selected_action.type != 'action_send_mqtt_message' &&
                    selected_action.type != 'action_animate' &&
                    selected_action.type != 'action_show' &&
                    selected_action.type != 'action_spin' &&
                    selected_action.type != 'action_transform_to' &&
                    selected_action.type != 'action_transform_by' &&
                    selected_action.type != 'action_change_space' &&
                    selected_action.type != 'action_start_following' &&
                    selected_action.type != 'action_show_instructions'
                "
              ></template>
              <template v-else>
                <vs-collapse-item>
                  <div slot="header" class="font-semibold">OPTIONS</div>
                  <div v-if="selected_action_clone" class="tab-general md:ml-4 md:mt-0 mt-0 ml-0">
                    <FormulateForm
                      v-if="selected_action.type == 'action_show'"
                      :values="selected_action_clone"
                      @events="onActionFormChanged"
                      :schema="action_show_form_schema"
                      :ferrors="{}"
                    >
                      <FormulateInput
                        v-if="selected_action.entrance_type === 'scale_up'"
                        key="show_scale_factor"
                        name="scale_factor"
                        label="Scale mutiplier (number):"
                        v-model="selected_action.scale_factor"
                      />
                      <FormulateInput
                        v-if="selected_action.entrance_type !== 'scale_up' && selected_action.entrance_type !== 'none'"
                        key="show_distance"
                        name="distance"
                        label="Distance (meters):"
                        v-model="selected_action.distance"
                      />
                    </FormulateForm>
                    <FormulateForm
                      v-if="selected_action.type == 'action_hide'"
                      :values="selected_action_clone"
                      @events="onActionFormChanged"
                      :schema="action_hide_form_schema"
                      :ferrors="{}"
                    >
                      <FormulateInput
                        v-if="selected_action.exit_type === 'scale_up' || selected_action.exit_type === 'scale_down'"
                        key="hide_scale_factor"
                        name="scale_factor"
                        label="Scale mutiplier (number):"
                        v-model="selected_action.scale_factor"
                      />
                      <FormulateInput
                        v-if="selected_action.exit_type !== 'scale_up' && selected_action.exit_type !== 'scale_down' && selected_action.exit_type !== 'none'"
                        key="hide_distance"
                        name="distance"
                        label="Distance (meters):"
                        v-model="selected_action.distance"
                      />
                    </FormulateForm>
                    <FormulateForm
                      v-if="selected_action.type == 'action_spin'"
                      :values="selected_action_clone"
                      @events="onActionFormChanged"
                      :schema="action_spin_form_schema"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_transform_to'"
                      :values="selected_action_clone"
                      @events="onActionFormChanged"
                      :schema="action_transform_to"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_transform_by'"
                      :values="selected_action_clone"
                      @events="onActionFormChanged"
                      :schema="action_transform_by"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_animate'"
                      :values="selected_action_clone"
                      :schema="action_animate_form_schema"
                      @events="onActionFormChanged"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_play_particles'"
                      :values="selected_action_clone"
                      :schema="action_play_particles_form_schema"
                      @events="onActionFormChanged"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_open_url'"
                      :values="selected_action_clone"
                      :schema="action_open_url_form_schema"
                      @events="onActionFormChanged"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_change_space'"
                      :values="selected_action_clone"
                      :schema="action_change_space_form_schema"
                      @events="onActionFormChanged"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_show_instructions'"
                      :values="selected_action_clone"
                      :schema="action_show_instructions_form_schema"
                      @events="onActionFormChanged"
                    />
                    <FormulateForm
                      v-if="selected_action.type == 'action_start_following'"
                      :values="selected_action_clone"
                      :schema="action_start_following_form_schema"
                      @events="onActionFormChanged"
                    />
                  </div>
                </vs-collapse-item>
              </template>
            </vs-collapse>
          </div>
        </div>
        <div v-else>
          <h4 class="m-4">To create an action click on "Add Action"</h4>
        </div>
      </vs-sidebar>
    </div>
    <!-- <vx-card class="mb-4">
        <vs-checkbox v-model="show_sequencing_json">Show JSON (Advanced User Only)</vs-checkbox>
        <vue-json-pretty v-if="show_sequencing_json" :path="'res'" :data="buildJsonSequence()"> </vue-json-pretty>
      </vx-card> -->
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/12 xl:w-3/12">
      <vx-card class="mt-3">
        <vs-checkbox v-model="show_sequencing_json">Show JSON</vs-checkbox>
        <vue-json-pretty v-if="show_sequencing_json" :path="'res'" :data="buildJsonSequence()"> </vue-json-pretty>

        <!--       
      <vs-checkbox v-model="show_vue_sequencing_json">Show JSON</vs-checkbox>
      <vue-json-pretty v-if="show_vue_sequencing_json" :path="'res'" :data="this.sequences"> </vue-json-pretty> -->
      </vx-card>
    </div>
    <vx-card :card-background="getBackgroundColor(selected_sequence)" @click.stop="selectSequence(selected_sequence)">
    </vx-card>
  </div>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import draggable from 'vuedraggable'
import { uuid } from 'vue-uuid'
import vSelect from 'vue-select'
import VxCard from '../../../../components/vx-card/VxCard.vue'
import 'vue-select-image/dist/vue-select-image.css'
import '@braid/vue-formulate/themes/snow/snow.scss'
import { io } from 'socket.io-client'
import { v4 as uuidv4 } from 'uuid'

// Forms
import { actionShowSchema, actionShowValue } from './forms/ActionShow.js'
import { actionHideSchema, actionHideValue } from './forms/ActionHide.js'
import { actionAnimateSchema, actionAnimateValue } from './forms/ActionAnimate.js'
import { actionSpinSchema, actionSpinValue } from './forms/ActionSpin.js'
import { actionTransformToSchema, actionTransformToValue } from './forms/ActionTransformTo.js'
import { actionTransformBySchema, actionTransformByValue } from './forms/ActionTransformBy.js'
import { actionPlayParticlesSchema, actionPlayParticlesValue } from './forms/ActionPlayParticules.js'
import { actionOpenUrlSchema, actionOpenUrlValue } from './forms/ActionOpenUrl.js'
import { actionChangeSpaceSchema, actionChangeSpaceValue } from './forms/ActionChangeSpace.js'
import { actionShowInstructionSchema, actionShowInstructionValue } from './forms/ActionShowInstruction.js'
import { actionStartFollowingSchema, actionStartFollowingValue } from './forms/ActionStartFollowing.js'

// Form events
import { ref } from '@vue/composition-api'
import Avatar from '../../../components/vuesax/avatar/Avatar.vue'

export default {
  inject: ['$validator'],
  data() {
    let experienceTriggerTypesDic = {
        trigger_experience_starts: 'Immediately',
        trigger_tap_to_place: 'User pins experience',
        //trigger_match_to_place: 'Match to Place',
        trigger_screen_tap: 'User taps screen',
        // near_range_in: 'Near Range In',
        trigger_image_anchor_found: 'User points to image',
        // trigger_image_anchor_lost: 'Image Lost',
        // trigger_mqtt_message_received: 'MQTT Message Received',
        // trigger_amqp_message_received: 'AMQP Message Received',
      };
      let objectTriggerTypesDic = { 
        trigger_tap: 'User taps object',
        trigger_proximity_enter: 'User moves close to object',
        trigger_proximity_exit: 'User moves away from object',
        trigger_enter_portal: 'User enters 360 portal',
        trigger_exit_portal: 'User exits 360 portal', 
      };
      let allTriggerTypesDic = {
        ...experienceTriggerTypesDic,
        ...objectTriggerTypesDic
      };
    return {
      socket: null,
      editorSessionId: null,
      hoverlayIconSrc: require('@/assets/images/hoverlay/construction.png'),
      qrCodeUrl: null,
      events: ref([]),
      values: {},
      action_show_form_schema: actionShowSchema,
      action_hide_form_schema: actionHideSchema,
      action_animate_form_schema: actionAnimateSchema,
      action_spin_form_schema: actionSpinSchema,
      action_transform_to: actionTransformToSchema,
      action_transform_by: actionTransformBySchema,
      action_play_particles_form_schema: actionPlayParticlesSchema,
      action_open_url_form_schema: actionOpenUrlSchema,
      action_change_space_form_schema: actionChangeSpaceSchema,
      action_show_instructions_form_schema: actionShowInstructionSchema,
      action_start_following_form_schema: actionStartFollowingSchema,
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      selected_sequence: null,
      actionTabIndex: 1,
      selected_actions: null,
      selected_action: null,
      selected_action_clone: null,
      selected_action_type: { text: 'Animate Entrance', value: 'action_show' },
      sequences: [],
      triggerTypeOptions: [],
      selectedReferenceImage: null,
      referenceImageOptions: null,
      actionsStartDic: {
        with_previous: 'with previous',
        after_previous: 'after previous',
      },
      spacePidNameOptions: [],
      entranceTypeOptions: [],
      exitTypeOptions: [],
      nodePidHobjectNameOptions: [],
      actionsStartOptions: [],
      axisOptions: [],
      directionOptions: [],
      axisDic: {
        right: 'Right',
        up: 'Up',
        rear: 'Rear',
      },
      directionDic: {
        clockwise: 'Clockwise',
        counterclockwise: 'Counterclockwise',
      },
      experienceTriggerTypesDic,
      objectTriggerTypesDic,
      allTriggerTypesDic,
      iconActionDic: {
        action_show: 'visibility',
        action_hide: 'visibility_off',
        action_play: 'play_arrow',
        action_pause: 'pause',
        action_toggle_play_pause: 'toggle_on',
        action_stop: 'stop',
        action_animate: 'animation',
        action_spin: '360',
        action_transform_to: 'move_up',
        action_transform_by: 'photo_size_select_small',
        action_play_particles: 'snowing',
        action_change_space: 'double_arrow',
        action_open_url: 'link',
        action_show_instructions: 'notes',
        action_set_experience_origin: 'ads_click',
        action_start_following: 'phonelink_lock',
        action_stop_following: 'phonelink_erase',
        action_send_amqp_message: 'SendIcon',
        action_send_mqtt_message: 'SendIcon',
      },
      actionTypesDic: {
        action_show: 'Animate Entrance',
        action_hide: 'Animate Exit',
        action_play: 'Play',
        action_pause: 'Pause',
        action_toggle_play_pause: 'Toggle Play Pause',
        action_stop: 'Stop',
        action_animate: 'Animate',
        action_spin: 'Spin',
        action_play_particles: 'Play Particles',
        action_change_space: 'Change Space',
        action_open_url: 'Open Link',
        action_show_instructions: 'Show Instructions',
        action_set_experience_origin: 'Reset User Origin',
        // action_send_amqp_message: 'Send AMQP message ',
        // action_send_mqtt_message: 'Send MQTT message ',
      },
      actionTypes: [],
      is_action_inspector_open: ref(false),
      is_navigation_sidebar_open: ref(true),
      show_sequencing_json: false,
      show_vue_sequencing_json: false,
      is_sequence_name_editing: false,
      temp_sequence_name: null,
    }
  },
  props: {
    space: {},
    hobjects: {},
    nodes: {},
    anchors_spaces: {},
    anchors: {},
    nodePidHobjectNameDictionnary: {},
    spacePidNameDictionnary: {},
  },
  components: {
    VueJsonPretty,
    draggable,
    'v-select': vSelect,
    VxCard,
  },
  watch: {
    selected_sequence: function(updatedValue) {
      if (updatedValue) this.selected_actions = updatedValue.actions
    },
    selected_action: function(updatedValue) {
      this.selected_action_clone = null
      // Clone selected_action in the next tick (to overcome vue-formulate issue)
      this.$nextTick(() => {
        this.selected_action_clone = JSON.parse(JSON.stringify(updatedValue))
      })
    },
  },
  computed: {
    space_pid: function() {
      return this.$route.params.pid
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  mounted() {},
  async created() {
    // Populate dictionnaries
    for (const [key, value] of Object.entries(this.actionTypesDic)) {
      this.actionTypes.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.spacePidNameDictionnary)) {
      this.spacePidNameOptions.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.nodePidHobjectNameDictionnary)) {
      this.nodePidHobjectNameOptions.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.allTriggerTypesDic)) {
      this.triggerTypeOptions.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.actionsStartDic)) {
      this.actionsStartOptions.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.directionDic)) {
      this.directionOptions.push({ text: value, value: key })
    }
    for (const [key, value] of Object.entries(this.axisDic)) {
      this.axisOptions.push({ text: value, value: key })
    }

    // Hide action inspector
    this.is_action_inspector_open = false

    // Populate ActionChangeSpaceFormSchema
    this.action_change_space_form_schema[0].options = this.spacePidNameDictionnary

    // Populate reference for ActionChangeSpace when keep_origin is true
    // this.action_change_space_form_schema[2].options = this.nodePidHobjectNameDictionnary

    this.referenceImageOptions = []
    this.anchors_spaces.forEach(as => {
      if (as.anchor.anchor_type == 'image' || as.anchor.anchor_type == 'vuforia-instant-image-target')
        this.referenceImageOptions.push({
          id: as.anchor.pid,
          name: as.anchor.name,
          src: as.anchor.data,
          value: as.anchor.pid,
          alt: 'none',
        })
    })

    // console.log(this.space.storylines)
    if (
      this.space.storylines == null ||
      this.space.storylines.sequences == null ||
      !this.isValid(this.space.storylines.sequences)
    ) {
      this.AddSequence(null)
    } else {
      this.sequences = this.deserialize(this.space.storylines.sequences)
    }
    // console.log(this.sequences[0])
    this.selectSequence(this.sequences[0])

    this.editorSessionId = localStorage.getItem('editorSessionId')
    if (!this.editorSessionId) {
      localStorage.setItem('editorSessionId', uuidv4())
      this.editorSessionId = localStorage.getItem('editorSessionId')
    }

    this.qrCodeUrl = `https://hoverlay.io/space/${this.space.pid}?editor_token=${this.editorSessionId}-${this.space.pid}`
    // connect to the websocket
    this.socket = io('https://hov-event-bridge.herokuapp.com/storyline-webapp', {
      query: {
        editor_token: `${this.editorSessionId}-${this.space.pid}`,
      },
    })
    this.socket.on('send_event', args => {
      args = JSON.parse(args)
      console.log(args)
      if (args.event_name == 'onSequenceElementStarted') {
        var element = document.getElementById(args.sequence_element_id)
        if (element) element.classList.add('sequence-element-started')
      } else if (args.event_name == 'onSequenceElementEnded') {
        element = document.getElementById(args.sequence_element_id)
        if (element) element.classList.remove('sequence-element-started')
      } else if (args.event_name == 'onSequenceElementCanceled') {
        element = document.getElementById(args.sequence_element_id)
        if (element) element.classList.add('sequence-element-canceled')
      }
    })
  },
  methods: {
    startSequence(sequence_index) {
      console.log('startSequence')
      this.socket.emit('start_sequence', {
        editor_token: this.editorSessionId,
        sequence_index,
      })
    },
    async onQrCodeDownload() {
      var res = await fetch(this.qrCodeData64)
      var blob = await res.blob()
      var a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'

      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = this.space.name
      a.click()
      window.URL.revokeObjectURL(url)
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrTestCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },
    getData64fromQrCode(data64, _) {
      this.qrCodeData64 = data64
    },
    showActionDuration(action) {
      var text = ''
      if (action.duration > 0) text += ` in ${action.duration}s`
      return text
    },
    showTriggerSubtitle(sequence) {
      if (!sequence.targets) return sequence.name
      var hobjectName = sequence.targets[0] ? sequence.targets[0].text : sequence.name
      var othersHobject = ''
      if (sequence.targets.length == 2) {
        othersHobject = `+ ${sequence.targets.length - 1} other `
      }
      if (sequence.targets.length > 2) {
        othersHobject = `+ ${sequence.targets.length - 1} others `
      }
      return `${hobjectName} ${othersHobject}`
    },
    showActionSubtitle(action) {
      var text = ''
      var hobjectName = ''
      var othersHobject = ''
      var verbTermination = ''

      if (action.target_all_objects == true) {
        hobjectName = 'All objects'
      } else {
        if (action.targets) {
          hobjectName = action.targets[0] ? action.targets[0].text : ''
          if (action.targets.length == 2) {
            othersHobject = `(+ ${action.targets.length - 1}) `
          }
          if (action.targets.length > 2) {
            othersHobject = `(+ ${action.targets.length - 1})`
          }
          verbTermination = action.targets.length == 1 ? 'S' : ''
        }
      }

      if (action.type == 'action_show') text = `${hobjectName} ${othersHobject} ENTER` + verbTermination
      if (action.type == 'action_hide') text = `${hobjectName} ${othersHobject} EXIT` + verbTermination
      if (action.type == 'action_play') text = `${hobjectName} ${othersHobject} PLAY` + verbTermination
      if (action.type == 'action_pause') text = `${hobjectName} ${othersHobject} PAUSE` + verbTermination
      if (action.type == 'action_toggle_play_pause')
        text = `${hobjectName} ${othersHobject} TOGGLE PLAY/PAUSE` + verbTermination
      if (action.type == 'action_stop') text = `${hobjectName} ${othersHobject} STOP` + verbTermination
      if (action.type == 'action_animate') text = `PLAY ANIMATION on ${hobjectName} ${othersHobject}`
      if (action.type == 'action_spin') text = `SPIN on ${hobjectName} ${othersHobject}`
      if (action.type == 'action_transform_to') text = `Move, Rotate, Scale to`
      if (action.type == 'action_transform_by') text = `Move, Rotate, Scale by`
      if (action.type == 'action_play_particles') text = `PLAY PARTICLES on ${hobjectName} ${othersHobject}`
      if (action.type == 'action_send_amqp_message') text = `SEND ${action.message} to ${action.host}`
      if (action.type == 'action_send_mqtt_message') text = `SEND ${action.message} to ${action.host}`
      if (action.type == 'action_change_space') text = 'CHANGE SPACE'
      if (action.type == 'action_show_instructions') text = 'SHOW INSTRUCTIONS'
      if (action.type == 'action_open_url') text = 'OPEN LINK'
      if (action.type == 'action_set_experience_origin') text = 'RESET USER ORIGIN'
      if (action.type == 'action_start_following') text = 'Start Tagging Along Screen'
      if (action.type == 'action_stop_following') text = 'Stop Tagging Along Screen'

      return text
    },
    removeTrigger: function(t) {
      console.log('removeTrigger')
      const index = this.sequences.indexOf(t)
      if (index > -1) {
        this.sequences.splice(index, 1)
      }

      if (this.selected_sequence == null && this.sequences.length > 0) this.selectSequence(this.sequences[0])
    },
    removeAction: function(a) {
      if (this.selected_action && this.selected_action.id == a.id) this.selected_action = undefined
      this.sequences.forEach(trigger => {
        var action = trigger.actions.find(action => action.id == a.id)
        if (action) {
          const index = trigger.actions.indexOf(action)
          if (index > -1) {
            trigger.actions.splice(index, 1)
          }
          console.log(action)
        }
        // console.log(action)
      })
    },
    RemoveSequence: function(sequence) {
      var index
      var tmp
      if (sequence) tmp = this.sequences.find(seq => seq.id == sequence.id)
      else tmp = this.sequences.find(seq => seq.id == this.selected_sequence.id)
      index = this.sequences.indexOf(tmp)

      // console.log(this.sequences)
      // console.log(sequence)
      // console.log(index)

      if (index > 0) {
        this.selectSequence(this.sequences[index - 1])
      } else {
        this.selectSequence(null)
      }
      if (index > -1) {
        this.sequences.splice(index, 1) // 2nd parameter means remove one item only
      }

      // Unselect action
      this.SelectAction(null)

      // console.log(this.sequences)
    },
    AddSequence: function(trigger_type) {
      if (!trigger_type) trigger_type = { value: 'trigger_experience_starts', text: 'Immediately' }
      var repeat = false
      switch (trigger_type.value) {
        case 'trigger_tap':
        case 'trigger_proximity_enter':
        case 'trigger_proximity_exit':
        case 'trigger_enter_portal':
        case 'trigger_exit_portal':
          repeat = true
          break
        default:
          break
      }
      this.sequences.push({
        name: `Sequence ${this.sequences.length + 1}`,
        actions: [],
        trigger: { trigger_type: trigger_type, id: uuid.v4() },
        id: uuid.v4(),
        repeat: repeat,
      })
      this.selectSequence(this.sequences[this.sequences.length - 1])
      // console.log(this.selected_sequence)
    },
    AddAction(selected_action_type) {
      const actionId = uuid.v4()
      var commonProperties = {
        id: actionId,
        type: selected_action_type,
        start: { value: 'after_previous', text: 'After Previous' },
        targets: [{ text: this.nodePidHobjectNameDictionnary[this.nodes[0].pid], value: this.nodes[0].pid }],
        delay: 0,
        repeat: 0,
      }
      var actionProperties = {}
      if (selected_action_type == 'action_spin') actionProperties = actionSpinValue
      if (selected_action_type == 'action_transform_by') actionProperties = actionTransformByValue
      if (selected_action_type == 'action_transform_to') actionProperties = actionTransformToValue
      if (selected_action_type == 'action_show') actionProperties = actionShowValue
      if (selected_action_type == 'action_hide') actionProperties = actionHideValue
      if (selected_action_type == 'action_animate') actionProperties = actionAnimateValue
      if (selected_action_type == 'action_play_particles') actionProperties = actionPlayParticlesValue
      if (selected_action_type == 'action_open_url') actionProperties = actionOpenUrlValue
      if (selected_action_type == 'action_show_instructions') actionProperties = actionShowInstructionValue
      if (selected_action_type == 'action_start_following') actionProperties = actionStartFollowingValue
      this.selected_sequence.actions.push({
        ...actionProperties,
        ...commonProperties,
      })
      // Then select the action we just added
      this.SelectAction(actionId)
      // Selected second action tab by default
      this.actionTabIndex = 1
    },
    selectSequence(sequence) {
      this.selected_sequence = sequence
      this.is_action_inspector_open = false
      this.disableSequenceNameEditing();
    },
    getBackgroundColor() {
      return '#2816df'
      // if (this.selected_sequence && this.selected_sequence.id == trigger.id) return '#8379f2'
      // else return '#e9e7fd'
    },
    async updateSpace() {
      await this.$store.dispatch('hoverlay/updateSpace', {
        pid: this.space_pid,
        storylines: { sequences: this.buildJsonSequence() },
      })
      this.$vs.notify({
        text: 'Sequences saved',
        color: 'success',
        icon: 'check_box',
      })
    },
    getActionClass(action) {
      if (action.start.value == 'after_previous') return 'after-previous'
      else return 'with-previous'
    },

    // DESERIALIZER
    deserialize: function(json) {
      // json = [
      //   {
      //     type: 'sequential',
      //     elements: [
      //       { type: 'trigger_experience_starts', targets: [{ text: '4ee99d8a-e-RealityLab', value: 'gtceb' }] },
      //       {
      //         type: 'parallel',
      //         elements: [
      //           { type: 'show', targets: ['gtceb'], duration: 1, delay: 3 },
      //           { type: 'hide', targets: ['gtceb'], duration: 2, delay: 4 },
      //         ],
      //       },
      //       // {
      //       //   type: 'parallel',
      //       //   elements: [
      //       //     { type: 'show3', targets: ['gtceb'], duration: 0, delay: 0 },
      //       //     { type: 'show4', targets: ['gtceb'], duration: 0, delay: 0 },
      //       //   ],
      //       // },
      //     ],
      //   },
      // ]

      var sequences = []

      json.forEach(sequence => {
        var s = {
          trigger: {
            id: sequence.elements[0].id,
            trigger_type: { value: sequence.elements[0].type, text: this.allTriggerTypesDic[sequence.elements[0].type] },
          },
          targets: this.getElementTargets(sequence.elements[0]),
          target: this.getElementTarget(sequence.elements[0]),
          actions: [],
          id: sequence.id,
          name: sequence.name,
        }
        // trigger_image_anchor_found
        // trigger_image_anchor_lost
        if (
          sequence.elements[0].type == 'trigger_image_anchor_found' ||
          sequence.elements[0].type == 'trigger_image_anchor_lost'
        ) {
          console.log(sequence.elements[0].anchor)
          if (sequence.elements[0].anchor != null) {
            var a = this.referenceImageOptions.find(ref => ref.id == sequence.elements[0].anchor)
            s.anchor = a
            console.log(s)
            var anchor = this.anchors.find(a => a.pid == sequence.elements[0].anchor)
            s.selectedReferenceImage = {
              id: anchor.pid,
              src: anchor.data,
              value: anchor.pid,
              alt: 'none',
            }
          }
        }

        if (
          sequence.elements[0].type == 'trigger_image_anchor_found' ||
          sequence.elements[0].type == 'trigger_image_anchor_lost' ||
          sequence.elements[0].type == 'trigger_screen_tap'
        ) {
          s.instructions_text = sequence.elements[0].instructions_text
          s.show_instructions = sequence.elements[0].show_instructions
          // s.instructions_text_background = sequence.elements[0].instructions_text_background
        }
        if (sequence.elements[0].type == 'trigger_match_to_place') {
          s.preview_instructions_text = sequence.elements[0].preview_instructions_text
          s.preview_button_text = sequence.elements[0].preview_button_text
          s.instructions_text = sequence.elements[0].instructions_text
          s.instructions_button_text = sequence.elements[0].instructions_button_text
          s.show_preview = sequence.elements[0].show_preview
          s.smooth_time = sequence.elements[0].smooth_time
          s.max_speed = sequence.elements[0].max_speed
        }
        // trigger_mqtt_message_received
        if (sequence.elements[0].type == 'trigger_mqtt_message_received') {
          s.host = sequence.elements[0].host
          s.username = sequence.elements[0].username
          s.password = sequence.elements[0].password
          s.read_topic = sequence.elements[0].read_topic
        }

        // trigger_amqp_message_received
        if (sequence.elements[0].type == 'trigger_amqp_message_received') {
          s.host = sequence.elements[0].host
          s.username = sequence.elements[0].username
          s.password = sequence.elements[0].password
          s.port = sequence.elements[0].port
          s.read_topic = sequence.elements[0].read_topic
          s.exchange = sequence.elements[0].exchange
          s.routing_key = sequence.elements[0].routing_key
          s.virtual_host = sequence.elements[0].virtual_host
          s.selected_token = sequence.elements[0].selected_token
          s.message = sequence.elements[0].message
        }

        // trigger_amqp_message_received
        if (
          sequence.elements[0].type == 'trigger_proximity_enter' ||
          sequence.elements[0].type == 'trigger_proximity_exit'
        ) {
          s.distance = parseFloat(sequence.elements[0].distance)
        }

        for (let y = 1; y < sequence.elements.length; y++) {
          // console.log(sequence.elements[y])
          if (sequence.elements[y].type == 'parallel') {
            // console.log('parallel')
            s.actions.push(this.deserializeElement(sequence.elements[y].elements[0], 'after_previous'))
            for (let i = 1; i < sequence.elements[y].elements.length; i++) {
              s.actions.push(this.deserializeElement(sequence.elements[y].elements[i], 'with_previous'))
            }
          } else {
            // console.log('seq ')

            s.actions.push(this.deserializeElement(sequence.elements[y], 'after_previous'))
          }
        }
        if (sequence.name) s.name = sequence.name
        else s.name = `Sequence ${json.indexOf(sequence) + 1}`

        // Repeat
        if (sequence.repeat == -1 || sequence.repeat == true) s.repeat = true
        else s.repeat = false

        sequences.push(s)
      })
      // console.log(sequences)
      return sequences
    },
    getHobjectAnimationFromNodePid(nodePid) {
      var hobjectPid = this.nodes.find(n => n.pid == nodePid).hobject_pid
      var h = this.hobjects.find(h => h.pid == hobjectPid)
      var abilities = JSON.parse(h.abilities)
      if (abilities.gltf_loader) return abilities.gltf_loader.animation_clip_names
    },
    getElementTarget: function(element) {
      var target = ''
      if (element.target) return { text: this.nodePidHobjectNameDictionnary[element.target], value: element.target }
      else return target
    },
    getElementTargets: function(element) {
      var targets = []
      if (element.targets && element.targets.length > 0)
        element.targets.forEach(target => {
          targets.push({ text: this.nodePidHobjectNameDictionnary[target], value: target })
        })
      return targets
    },
    goToEditSpace() {
      this.$router.push({
        path: `/space/${this.space.pid}`,
      })
    },
    deserializeElement: function(element, start_after) {
      // create copy
      element = JSON.parse(JSON.stringify(element))
      var targets = []
      if (element.targets) {
        element.targets.forEach(target => {
          targets.push({ text: this.nodePidHobjectNameDictionnary[target], value: target })
        })
      }
      element.targets = targets
      element.start = {
        value: start_after,
        text: this.actionsStartDic[start_after],
      }
      return element
    },

    /// SERIALIZER
    buildJsonSequenceElement: function(action) {
      // Duplicate action
      action = JSON.parse(JSON.stringify(action))
      // Action may or may not have associated target(s)
      if (action.targets && action.targets.length > 0) {
        var targets = []
        action.targets.forEach(target => {
          targets.push(target.value)
        })
        action.targets = targets
      }
      if (action.start) action.start = action.start.value
      return action
    },
    buildJsonparallel: function(actions) {
      var block = { type: 'parallel', elements: [] }
      for (let index = 0; index < actions.length; index++) {
        const action = actions[index]
        block.elements.push(this.buildJsonSequenceElement(action))
      }
      return block
    },
    buildJsonSequence: function() {
      var json = []
      this.sequences.forEach(sequence => {
        if (sequence.actions != null && sequence.actions.length > 0) {
          var el = {
            type: 'sequential',
            repeat: sequence.repeat == true ? -1 : 0,
            id: sequence.id,
            name: sequence.name,
            elements: [
              {
                type:
                  sequence.trigger.trigger_type != null && sequence.trigger.trigger_type.value != null
                    ? sequence.trigger.trigger_type.value
                    : null,
              },
            ],
          }
          el.elements[0].id = sequence.trigger.id
          if (sequence.trigger.trigger_type.value == 'trigger_screen_tap') {
            el.elements[0].instructions_text = sequence.instructions_text
            el.elements[0].show_instructions = sequence.show_instructions
          }
          if (
            (sequence.trigger.trigger_type.value == 'trigger_image_anchor_found' ||
              sequence.trigger.trigger_type.value == 'trigger_image_anchor_lost') &&
            sequence.anchor
          ) {
            // trigger_image_anchor_found & trigger_image_anchor_lost
            // Add anchor to sequence trigger if needed
            el.elements[0].anchor = sequence.anchor.id
            el.elements[0].instructions_text = sequence.instructions_text
            el.elements[0].show_instructions = sequence.show_instructions
            // el.elements[0].instructions_text_background = sequence.instructions_text_background
          }
          if (sequence.trigger.trigger_type.value == 'trigger_tap_to_place') {
            el.elements[0].target = sequence.target ? sequence.target.value : null
          }

          if (sequence.trigger.trigger_type.value == 'trigger_match_to_place') {
            el.elements[0].target = sequence.target ? sequence.target.value : null
            // el.elements[0].targets = Array.from(sequence.targets, target => target.value)
            el.elements[0].preview_instructions_text = sequence.preview_instructions_text
            el.elements[0].preview_button_text = sequence.preview_button_text
            el.elements[0].instructions_button_text = sequence.instructions_button_text
            el.elements[0].show_preview = sequence.show_preview
            el.elements[0].smooth_time = sequence.smooth_time
            el.elements[0].max_speed = sequence.max_speed
          }
          // trigger_mqtt_message_received
          if (sequence.trigger.trigger_type.value == 'trigger_mqtt_message_received') {
            el.elements[0].host = sequence.host
            el.elements[0].username = sequence.username
            el.elements[0].password = sequence.password
            el.elements[0].read_topic = sequence.read_topic
          }
          //trigger_amqp_message_received
          if (sequence.trigger.trigger_type.value == 'trigger_amqp_message_received') {
            el.elements[0].host = sequence.host
            el.elements[0].port = sequence.port
            el.elements[0].username = sequence.username
            el.elements[0].password = sequence.password
            el.elements[0].virtual_host = sequence.virtual_host
            el.elements[0].exchange = sequence.exchange
            el.elements[0].routing_key = sequence.routing_key
            el.elements[0].selected_token = sequence.selected_token
            el.elements[0].message = sequence.message
          }

          // trigger_tap
          if (sequence.trigger.trigger_type.value == 'trigger_tap' && sequence.targets) {
            el.elements[0].targets = Array.from(sequence.targets, target => target.value)
          }
          // trigger_enter_portal
          if (sequence.trigger.trigger_type.value == 'trigger_enter_portal' && sequence.targets) {
            el.elements[0].targets = Array.from(sequence.targets, target => target.value)
          }
          // trigger_exit_portal
          if (sequence.trigger.trigger_type.value == 'trigger_exit_portal' && sequence.targets) {
            el.elements[0].targets = Array.from(sequence.targets, target => target.value)
          }
          // trigger_proximity_enter
          if (sequence.trigger.trigger_type.value == 'trigger_proximity_enter' && sequence.targets) {
            el.elements[0].targets = Array.from(sequence.targets, target => target.value)
            el.elements[0].distance = parseFloat(sequence.distance)
          }
          // trigger_proximity_exit
          if (sequence.trigger.trigger_type.value == 'trigger_proximity_exit' && sequence.targets) {
            el.elements[0].targets = Array.from(sequence.targets, target => target.value)
            el.elements[0].distance = parseFloat(sequence.distance)
          }
          if (sequence.actions != null && sequence.actions.length > 0) {
            // Add target to sequence trigger if needed
            // targets: [sequence.target != null && sequence.target.value != null ? sequence.target.value : null],

            for (let index = 0; index < sequence.actions.length; index++) {
              const action = sequence.actions[index]
              if (index == sequence.actions.length - 1 || sequence.actions[index + 1].start.value == 'after_previous') {
                el.elements.push(this.buildJsonSequenceElement(action))
              } else if (sequence.actions[index + 1].start.value == 'with_previous') {
                var parallel_actions = []
                parallel_actions.push(sequence.actions[index])
                index++
                parallel_actions.push(sequence.actions[index])
                let i = index + 1
                while (i < sequence.actions.length && sequence.actions[i].start.value == 'with_previous') {
                  parallel_actions.push(sequence.actions[i])
                  index++
                  i++
                }
                el.elements.push(this.buildJsonparallel(parallel_actions))
              } else {
                continue
              }
            }
          }
          json.push(el)
        }
      })
      return json
    },
    isValid: function(sequencing) {
      if (sequencing == null || !(sequencing.length > 0)) {
        // console.log('isValid false')
        return false
      } else {
        // console.log('isValid true')

        return true
      }
    },
    async SelectAction(action_id) {

      // Save current edits to selected_action_clone
      if (this.selected_action != null && this.selected_action_clone != null)
      {
        console.log ("Saving edits to selected_action_clone")
        console.log(this.selected_action_clone)
        this.selected_actions[this.selected_action_clone.id] = this.selected_action_clone
      }

      if (action_id == null) {
        this.selected_action = null
        this.is_action_inspector_open = false;
        return
      }

      this.selected_action = this.selected_actions.find(a => a.id == action_id)
      console.log('SelectAction')
      console.log(this.selected_action)

      // Open inspector if needed
      this.is_action_inspector_open = true

      // Populate animation clip names
      if (
        this.selected_action.type == 'action_animate' &&
        this.getHobjectAnimationFromNodePid(this.selected_action.targets[0].value) &&
        this.getHobjectAnimationFromNodePid(this.selected_action.targets[0].value).length > 0
      ) {
        this.action_animate_form_schema[0].type = 'select'
        var anims = {}
        this.getHobjectAnimationFromNodePid(this.selected_action.targets[0].value).forEach(a => {
          anims[a] = a
        })
        this.action_animate_form_schema[0].options = anims
      } else {
        this.action_animate_form_schema[0].type = 'text'
      }
    },
    ToggleActionSidebar() {
      this.is_action_inspector_open = !this.is_action_inspector_open;
    },
    ShowAction(action_id, a) {
      this.selected_actions.forEach(a => {
        if (a.id != action_id) a.show = false
      })
      console.log('ShowAction')
      var action = this.selected_actions.find(a => a.id == action_id)
      console.log(a.show)
      if (action) {
        if (action.show == true) action.show = false
        else action.show = true
      }
      // console.log(action.show)
    },
    Back() {
      this.$router.go(-1)
    },
    onActionFormChanged(e) {
      this.selected_action[e.name] = e.payload
    },
    // Sequence Name Editing
    enableSequenceNameEditing: function() {
      this.temp_sequence_name = this.selected_sequence.name
      this.is_sequence_name_editing = true
    },
    disableSequenceNameEditing: function() {
      this.temp_sequence_name = null
      this.is_sequence_name_editing = false
    },
    saveSequenceNameEdit: function() {
      var finalName = this.temp_sequence_name.replace(/(\r\n|\n|\r)/gm, "");
      if (finalName) {
        this.selected_sequence.name = finalName
        this.disableSequenceNameEditing()
      }
      this.is_sequence_name_editing = false
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/components/vxTimeline.scss';
.con-vs-tabs .con-slot-tabs {
  overflow: visible !important;
}
.con-vs-tabs {
  overflow: visible !important;
}
.border-dots {
  border-width: 1px;
  padding: 10px;
  // border: dashed #ecebfa;
  border: solid #d6d6d6;

  border-radius: 5px;
}
.vx-timeline > li {
  background-color: #f7f6fe;
}
.vx-timeline > div > li {
  background-color: #f7f6fe;
}
.vx-timeline > li:hover {
  background-color: #e6e4fc;
  border-radius: 5px;
}
.action-title-highlight:hover {
  color: #2614ed;
}
.vx-timeline > div > li:hover {
  background-color: #e6e4fc;
  border-radius: 5px;
}
.open-item > .vs-collapse-item--content {
  max-height: 99999px !important;
}
.open-item > * {
  overflow: visible !important;
}

.vx-timeline {
  li {
    margin-bottom: 0px;
  }
}

.hover:hover {
  text-decoration: underline;
}

// form schema
.formulate-input {
  margin-bottom: 0.5rem !important;
}
.formulate-input[data-classification='box'] .formulate-input-wrapper {
  display: flex;
  align-items: center;
}
.formulate-input[data-classification='box'] .formulate-input-label--after {
  margin-left: 0.5em;
}
.formulate-input .formulate-input-label {
  margin-bottom: 0.1em;
}
.icon-size > i {
  font-size: 1.6rem;
}
.action-type-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 0px;
  width: 36px; /* Adjust as needed */
  height: 36px; /* Adjust as needed */
  border-radius: 50%;
  color: white
}
.action-type-icon > i {
  font-size: 1rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 900;
}
.round-icon {
  border-radius: 50%;
  background-color: rgba(var(--vs-primary), 1);
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.text-gray {
  color: #8b8b8b;
}
/* Timeline Container */
.timeline {
  background: var(--primary-color);
  // margin: 20px auto;
  // padding: 20px;
}

/* Outer Layer with the timeline border */
.outer {
  /* border-left: 1px solid rgb(88, 88, 88); */
}

.outer > .card {
  background: white;
}

/* overall sequence panel */
#sequence-editor-app {
  background-image: radial-gradient(rgb(198, 198, 198) 1px, transparent 1px),
                    radial-gradient(rgb(198, 198, 198) 1px, transparent 1px);
  background-size: 20px 20px;
  background-position: 0 10px, 0 10px;
}

#workspace {
  overflow-y: auto;
  height: 70vh; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

#workspace::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

/* Card container */
.card {
  position: relative;
  margin-left: 0px;
  padding: 0 20px 0 20px;
  background: linear-gradient(90deg, rgba(248, 248, 248, 0.778), 0%, #fff 300%);
  border-radius: 10px;
  border: 1px solid rgb(255, 255, 255);
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px 0px
}
.selected-card {
  border: 3px solid white;
  background: #ebebff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 3px 0px
}

.with-previous {
  margin-top: 1px;
}
.after-previous {
  margin-top: 28px;
}
// .after-previous::before {
//   content: "";
//   position: absolute;
//   top: -28px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 1px;
//   height: 20px;
//   background-color: #6262626e; /* Replace with your desired line color */
// }
.after-previous::before {
  content: "";
  position: absolute;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent; /* Adjust as needed */
  border-right: 7px solid transparent; /* Adjust as needed */
  border-top: 14px solid #6262626e; /* Adjust as needed */
}
/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

/* Title of the card */
.title {
  color: orangered;
  position: relative;
}

/* Timeline dot  */
.title::before {
  content: '';
  position: absolute;

  width: 4px;
  height: 4px;
  background: white;
  border-radius: 999px;
  left: -39px;
  border: 3px solid rgb(166, 166, 166);
}
.header-sequence {
  display: flex;
  justify-content: space-between;
}
.sequence-element-started {
  background-color: rgb(1, 186, 8);
}
.sequence-element-canceled {
  background-color: rgb(156, 0, 0);
}
.break {
  flex-basis: 100%;
  height: 0;
}

// Create 1/3 width sidebar
.third-vs-sidebar {
  .vs-sidebar {
    max-width: calc(30%);
    margin-left: 0px;
  }
}

[contenteditable]:hover {
    cursor: text;
    text-decoration: underline;
}

[contenteditable]:focus {
  background-color: rgba(var(--v-theme-on-background), var(--v-hover-opacity));
  display:inline;
  overflow: hidden;
  border:none;
  outline:none;
  padding: .5em; 
}

@media only screen and (max-width: 992px) {
  .third-vs-sidebar {
    .vs-sidebar {
      max-width: 30%;
      margin-left: 0;
    }
  }
}
</style>
